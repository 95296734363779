import CookieConsent, { CookieConsentProps } from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { FaCookieBite } from "react-icons/fa";
import { FunctionComponent } from "react";
import { styled, theme } from "twin.macro";
import {
  buttonWrapperStyles,
  containerStyles,
  contentStyles,
  declineButtonStyles
} from "./styles";

const BannerDecorator: FunctionComponent<
  {
    className?: string;
  } & CookieConsentProps
> = ({ className, ...props }) => {
  const [name] = (className && className.split(" ")) || [" "];
  const styles = name
    ? {
        overlayClasses: name,
        buttonClasses: `${name}-button`,
        contentClasses: `${name}-content`,
        containerClasses: `${name}-container`,
        buttonWrapperClasses: `${name}-buttonwrapper`,
        declineButtonClasses: `${name}-declinebutton`
      }
    : {};
  return <CookieConsent {...styles} {...props} />;
};

const StyledBanner = styled(BannerDecorator)`
  &-container {
    ${containerStyles}
  }
  &-content {
    ${contentStyles}
  }
  &-buttonwrapper {
    ${buttonWrapperStyles}
  }
  &-declinebutton {
    ${declineButtonStyles}
  }
`;

export default function CookieBanner() {
  return (
    <StyledBanner
      disableStyles
      disableButtonStyles
      location="bottom"
      cookieName="gatsby-gdpr-google-analytics"
      buttonText="Accept"
      enableDeclineButton
      declineButtonText="Basic only"
      onAccept={() => {
        initializeAndTrack(window.location);
      }}
    >
      <FaCookieBite
        color={theme("colors.accent.500")}
        style={{ display: "inline-block" }}
        size="1.3em"
      />
      We use third-party cookies to analyze and track web traffic
    </StyledBanner>
  );
}
