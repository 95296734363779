module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.0.0_vtfv6cekxbdmiunjrwnn4oyx54/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1.5}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1}}]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gdpr-cookies@2.0.9_vtfv6cekxbdmiunjrwnn4oyx54/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-143154904-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"environments":["production","development"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.0.0_gatsby@5.0.1/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://runnerbangladesh.org","stripQueryString":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.0.0_gatsby@5.0.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Runner Bangladesh Development Society","short_name":"Runner Bangladesh","start_url":"/","background_color":"#222","theme_color":"#f59c22","display":"standalone","icon":"src/assets/rbds_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c022742a8c46bd3aeab7a10ae9b4bd7a"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.0.0_gatsby@5.0.1/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f59c22","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.0.1_yalvw3r2waubxycyb7k7qsruca/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
