import { formatDistanceStrictWithOptions, format } from "date-fns/fp";
import { enIN } from "date-fns/locale";
import { TwStyle } from "twin.macro";

/**
 * Converts slug strings to titlecase. Ex: "this-is-a-slug" converts to "This is a slug"
 */
export const makeTitleFromSlug = (slug: string) =>
  slug.replace(/-/g, " ").replace(/^\w/g, c => c.toUpperCase());

/**
 * Parse a date object into relative time, ex: "2 hours ago"
 */
export const relativeDate = (date: Date, currentDate = new Date()): string => {
  if (currentDate.getTime() - date.getTime() > 31556952000) {
    return format("d MMMM yyyy")(date);
  }
  return formatDistanceStrictWithOptions({ addSuffix: true, locale: enIN })(
    currentDate,
    date
  );
};

export const getExcerpt = (content: string, wordlimit = 20): string => {
  var filter = content.replace(/\s+/g, " ").replace(/<[^>]*>?/gm, ""); // You can add more filters here
  var wordsarr = filter.split(" ");

  if (wordsarr.length < wordlimit) {
    return wordsarr
      .reduce((prevWord, curWord) => prevWord.trim() + " " + curWord.trim())
      .trim();
  } else {
    return wordsarr
      .slice(0, wordlimit)
      .reduce((prevWord, curWord) => prevWord + " " + curWord)
      .trim()
      .concat("...");
  }
};

export function camelCase(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const isBrowser = typeof window !== "undefined";

export function isExternalLink(url: string) {
  return url.match(/(\/\/|:)/g) != null;
}

export function getContainerScrollRatio(element: HTMLElement) {
  const maxScrollLeft = element.scrollWidth - element.clientWidth;
  const ratio = parseFloat((1 - element.scrollLeft / maxScrollLeft).toFixed(1));
  return ratio;
}

export function htmlTemplate(
  html: string, // the template string
  vars: Record<string, string> // the values
) {
  return html.replace(
    /{% *([^} ]+) *%}/g, // searches for { variable_name } to replace
    function (
      substring, // no use here. it holds the entire "{ variable_name }" string
      arg // we will use this instead, it holds "variable_name"
    ) {
      substring = vars as any; // re-assign the placeholder to values
      arg.replace(/[^.]+/g, function (a: string) {
        // find all the key names
        substring = substring[a]; // one by one until it's reduced to the one we are looking for
      });
      return substring; // return this value to be replaced
    }
  );
}

export function setElementStyleFromObject(
  styles: Record<string, any>,
  element: HTMLElement
) {
  for (const [key, value] of Object.entries(styles)) {
    if (key.startsWith("--")) element.style.setProperty(key, value);
    else element.style[key] = value;
  }
}

function selectorExtract(key: string, selector: string) {
  // :hover, :focus -> a:hover, a:focus
  const split = key
    .split(",")
    .map(v => selector + v.trim())
    .join(",");
  return split;
}

export function styleObjectToString(
  styles: TwStyle,
  selector?: string
): [main: string, nested: string] {
  let cssString: string = "";
  let nested = "";

  for (let [key, value] of Object.entries(styles)) {
    key = key.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);
    if (typeof value === "string" || typeof value === "number") {
      cssString += `${key}:${value};`;
    } else if (
      key.startsWith(":hover") ||
      key.startsWith(":active") ||
      key.startsWith(":focus")
    ) {
      const nestedStyle = styleObjectToString(value);
      const nestedWithSelector = `${selectorExtract(
        key,
        selector
      )}{ ${nestedStyle} }`;
      nested = nestedWithSelector;
    }
  }

  return [cssString, nested];
}
