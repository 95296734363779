import { useLayoutEffect } from "react";

export function useLockBodyScroll(enabled: boolean) {
  if (!enabled) {
    return;
  }
  useLayoutEffect(() => {
    let marginRightPx = 0;
    if (window.getComputedStyle) {
      const bodyStyle = window.getComputedStyle(document.body);
      if (bodyStyle) {
        marginRightPx = parseInt(bodyStyle.marginRight, 10);
      }
    }
    const scrollbarWidthPx = window.innerWidth - document.body.clientWidth;

    document.body.style.overflow = "hidden";
    document.body.style.marginRight = `${marginRightPx + scrollbarWidthPx}px`;

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.marginRight = `0px`;
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
}
