import { useEffect, useState, useRef } from "react";
import { isBrowser } from "../utils";

// function getScrollTop() {
//   return isBrowser
//     ? window.pageYOffset ||
//         document.documentElement.scrollTop ||
//         document.body.scrollTop
//     : 0;
// }

export enum ScrollDirection {
  UP,
  DOWN,
  TOP
}

// export default function useScrollDirection(throttle = 100) {
//   const lastScrollY = useRef(getScrollTop());
//   const [scrollDir, setScrollDir] = useState(ScrollDirection.TOP);

//   const [handleScroll] = useDebouncedCallback(
//     useCallback(() => {
//       const scrollY = getScrollTop();
//       setScrollDir(
//         scrollY === 0
//           ? ScrollDirection.TOP
//           : scrollY > lastScrollY.current
//           ? ScrollDirection.DOWN
//           : ScrollDirection.UP
//       );
//       lastScrollY.current = scrollY;
//     }, [setScrollDir]),
//     throttle,
//     { maxWait: throttle }
//   );

//   useEffect(() => {
//     if (isBrowser) {
//       window.addEventListener("scroll", handleScroll);
//       return () => window.removeEventListener("scroll", handleScroll);
//     }
//   }, [handleScroll]);

//   return scrollDir;
// }

export function useScrollDirection() {
  const scrollValue = useRef(0);
  let scrollTimeout: NodeJS.Timeout;
  const [direction, setDirection] = useState(ScrollDirection.TOP);

  function handleScroll() {
    const scrolled = window.pageYOffset - scrollValue.current;

    if (scrolled < -250) {
      setDirection(ScrollDirection.UP);
    }
    if (scrolled > 0) {
      setDirection(ScrollDirection.DOWN);
    }

    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function () {
      scrollValue.current = window.pageYOffset;
    }, 100);
  }

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return direction;
}
