import {
  buttonWrapperStyles,
  containerStyles,
  contentStyles,
  declineButtonStyles
} from "./src/components/popups";
import { styleObjectToString } from "./src/lib";
import "@fontsource/roboto-slab/latin-500.css";
import "@fontsource/fira-sans/latin-400.css";
import "@fontsource/fira-sans/latin-500.css";

function renderSwUpdateDialog() {
  const [mainContainer, nestedContainer] = styleObjectToString(
    containerStyles,
    ".sw--container"
  );
  const [mainContent, nestedContent] = styleObjectToString(
    contentStyles,
    ".sw--content"
  );
  const [mainButtonWrapper, nestedButtonWrapper] = styleObjectToString(
    buttonWrapperStyles,
    ".sw--buttonwrapper"
  );
  const [mainDeclineButton, nestedDeclineButton] = styleObjectToString(
    declineButtonStyles,
    ".sw--declinebtn"
  );

  const styleSheet = `
    .sw--container { ${mainContainer} } ${nestedContainer}
    .sw--content { ${mainContent} } ${nestedContent}
    .sw--buttonwrapper { ${mainButtonWrapper} } ${nestedButtonWrapper}
    .sw--declinebtn { ${mainDeclineButton} } ${nestedDeclineButton}
  `;
  const styleElement = document.createElement("style");
  styleElement.innerHTML = styleSheet;

  const root = document.body.appendChild(document.createElement("div"));

  const container = document.createElement("div");
  container.className = "sw--container";

  const content = document.createElement("div");
  content.className = "sw--content";
  content.innerText = `New content is available for this website. Reload the page to update?`;

  const buttonWrapper = document.createElement("div");
  buttonWrapper.className = "sw--buttonwrapper";

  const declineButton = document.createElement("button");
  declineButton.className = "sw--declinebtn";
  declineButton.innerText = "Not now";
  declineButton.onclick = function () {
    document.body.removeChild(root);
  };

  const acceptButton = document.createElement("button");
  acceptButton.innerText = "Reload";
  acceptButton.onclick = function () {
    window.location.reload();
  };

  buttonWrapper.append(declineButton, acceptButton);
  container.append(content, buttonWrapper);
  root.append(container);
  root.append(styleElement);
}

export const onServiceWorkerUpdateReady = () => {
  renderSwUpdateDialog();
};
